import { EnvironmentEnum } from '@sites/util-environment';

export const environment = {
  name: EnvironmentEnum.Production,
  production: true,
  // Replaced by actual value during build
  release: 'c08b1b0794204f3bbdbee9cf3a0b0c7e24ae738f',

  dashboardUrl: 'https://dashboard.humanmademachine.com',

  apiUrl: 'https://api.humanmademachine.com',

  sentryDsn:
    'https://6504ab8504ec4403bbb00bfca4943b4c@o98546.ingest.sentry.io/6111916',
  sentrySampleRate: 1.0,
};
